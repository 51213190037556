<template>
  <v-dialog
    v-model="showSurvey"
    :max-width="fullscreen ? '' : 750"
    :persistent="!fullscreen"
    :hide-overlay="fullscreen"
    :fullscreen="fullscreen"
  >

    <v-card flat class="user-card-detail pa-sm-5">
      <v-app-bar
        dark
        color="primary"
        elevate-on-scroll
        fixed
        flat
        dense
        v-if="fullscreen"
      >
        <v-app-bar-title class="white--text ml-3">Responder Encuesta</v-app-bar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon color="white" >{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-app-bar>
      <v-row v-else>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-row>
      <v-row no-gutters>
        <survey :survey='survey'></survey>
      </v-row>
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
        v-if="loading"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
          Enviando su encuesta...
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import * as Survey from 'survey-vue'

import 'survey-vue/modern.css'
import { mdiClose } from '@mdi/js'
import { inject, reactive, ref } from '@vue/composition-api'
import useProjectsList from '@/views/projects/projects-list/useProjectsList'
let defaultThemeColors = Survey.StylesManager.ThemeColors['modern']
defaultThemeColors['$main-color'] = '#D3242B'
defaultThemeColors['$main-hover-color'] = 'rgba(211, 36, 43, 0.4)'
defaultThemeColors['$answer-background-color'] = 'rgba(211, 36, 43, 0.2)'
defaultThemeColors['$text-color'] = '#4a4a4a'
defaultThemeColors['$header-color'] = '#D3242B'
defaultThemeColors['$header-background-color'] = '#4a4a4a'
defaultThemeColors['$body-container-background-color'] = '#f8f8f8'
Survey.StylesManager.applyTheme('modern')
export default {
  props: {
    id: {
      default: 0,
      type: Number,
      required: false,
    },
    projectSurveyId: {
      default: 0,
      type: Number,
      required: false,
    },
    answer: {
      default: () => {},
      type: Object,
      required: false,
    },
  },
  setup(props, { emit }) {
    const json = inject('survey').value ? inject('survey').value : inject('survey')
    json.completedHtml = '<h1>Gracias por completar la evaluación.</h1>'
    const showSurvey = inject('showSurvey')
    const fullscreen = inject('fullscreen')
    const survey = new Survey.Model(json)
    const { updateProject } = useProjectsList()
    const user = JSON.parse(localStorage.getItem('userData'))
    const project = reactive({})
    const loading = ref(false)
    if (props.id) project.id = props.id

    const close = () => {
      showSurvey.value = false
    }

    if (props.answer && Object.keys(props.answer).length > 0) {
      survey.data = props.answer
    }

    survey.onComplete.add(val => {
      if (fullscreen) {
        loading.value = true
        let project_surveys_attributes = []
        let ele = {}
        if (props.answer && Object.keys(props.answer).length > 0) {
          ele = {
            id: props.projectSurveyId,
            project_id: props.id,
            user_id: user.id,
            structure_answer_string: survey.data,
            structure_answer: JSON.stringify(survey.data),
          }
        } else {
          ele = {
            project_id: props.id,
            user_id: user.id,
            structure_answer_string: survey.data,
            structure_answer: JSON.stringify(survey.data),
          }
        }

        project_surveys_attributes.push(ele)
        project['project_surveys_attributes'] = project_surveys_attributes
        console.log(project)
        updateProject(project)
          .then(() => {
            emit('updated')
            loading.value = false
            close()
          })
          .catch(error => {
            console.log(error)
            loading.value = false
          })
      } else {
        close()
      }
    })

    return {
      close,
      fullscreen,
      loading,
      icons: {
        mdiClose,
      },
      showSurvey,
      survey: survey,
    }
  },
}
</script>
